import axios from 'axios'
import { Commit } from 'vuex'

const url_api3 = process.env.VUE_APP_API_URL_3

export default {
    namespaced: true,
    state: {
        listDonasi: [],
        editDonasi: null,
    },
    getters: {
        getListDonasi: (state: { listDonasi: any[] }) => state.listDonasi,
    },
    mutations: {
        SET_LIST_DONASI(state: { listDonasi: any[] }, payload: any[]) {
            state.listDonasi = payload;
        },
        SET_EDIT_DONASI(state: { editDonasi: any[] }, payload: any[]) {
            state.editDonasi = payload;
        },
    },
    actions: {
        async listDonasi({ commit }: { commit: Commit }, payload: { index: number; limit: number }) {
            const { index, limit } = payload
            try {
                await axios.get(url_api3 + "donasi",
                    {
                        params: {
                            index: index,
                            limit: limit
                        },
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }
                )
                    .then((response) => {
                        commit('SET_LIST_DONASI', response.data)
                        console.log(response)
                    })
            } catch (error) {
                return error
            }
        },
        async editDonasi({ commit }: { commit: Commit }, payload: { id: string; name: string; description: string; target: number; accumulated: number; thumbnail: string; image: string; category: string }) {
            const { id, name, description, target, accumulated, thumbnail, image, category } = payload

            try {
                await axios.put(url_api3 + "donasi/update",
                    {
                        name,
                        description,
                        target,
                        accumulated,
                        thumbnail,
                        image,
                        category
                    },
                    {
                        params: {
                            id: id,
                        },
                        headers: {
                            "Authorization": `Bearer ${sessionStorage.getItem('token')}`
                        }
                    }
                )
                    .then((response) => {
                        commit('SET_EDIT_DONASI', response.data)
                        console.log(response)
                    })
            } catch (error) {
                return error
            }
        },
    },
}