<template>
    <div class="p-4 sm:ml-64">
        <div class="p-4">
            <div class="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
                <div class="grid gap-6 mb-6 md:grid-cols-1">
                    <nav class="flex" aria-label="Breadcrumb">
                        <ol class="inline-flex items-center space-x-1 md:space-x-2 rtl:space-x-reverse">
                            <li class="inline-flex items-center">
                                <a href="#"
                                    class="inline-flex items-center text-sm font-medium text-gray-700 hover:text-blue-600 dark:text-gray-400 dark:hover:text-white">
                                    <svg class="w-3 h-3 me-2.5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg"
                                        fill="currentColor" viewBox="0 0 20 20">
                                        <path
                                            d="m19.707 9.293-2-2-7-7a1 1 0 0 0-1.414 0l-7 7-2 2a1 1 0 0 0 1.414 1.414L2 10.414V18a2 2 0 0 0 2 2h3a1 1 0 0 0 1-1v-4a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1v4a1 1 0 0 0 1 1h3a2 2 0 0 0 2-2v-7.586l.293.293a1 1 0 0 0 1.414-1.414Z" />
                                    </svg>
                                    {{ breadCrumb.root.text }}
                                </a>
                            </li>
                            <li v-for="items in breadCrumb.child" :key="items.text">
                                <div class="flex items-center">
                                    <svg class="rtl:rotate-180 w-3 h-3 text-gray-400 mx-1" aria-hidden="true"
                                        xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                                            stroke-width="2" d="m1 9 4-4-4-4" />
                                    </svg>
                                    <a href="#"
                                        class="ms-1 text-sm font-medium text-gray-700 hover:text-blue-600 md:ms-2 dark:text-gray-400 dark:hover:text-white">{{
                                            items.text
                                        }}</a>
                                </div>
                            </li>
                        </ol>
                    </nav>
                </div>
                <div class="grid gap-1 mb-6 md:grid-cols-6">
                    <button @click="exportData('excel')" type="button"
                        class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-white focus:outline-none bg-black rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Export
                        to Excel</button>
                    <button @click="exportData('word')" type="button"
                        class="py-2.5 px-5 me-2 mb-2 text-sm font-medium text-white focus:outline-none bg-black rounded-lg border border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-4 focus:ring-gray-100 dark:focus:ring-gray-700 dark:bg-gray-800 dark:text-gray-400 dark:border-gray-600 dark:hover:text-white dark:hover:bg-gray-700">Export
                        to Word</button>
                </div>
                <div class="grid gap-6 mb-6 md:grid-cols-1">
                    <div>
                        <div class="relative overflow-x-auto shadow-md sm:rounded-lg">
                            <table class="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                <thead
                                    class="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                    <tr>
                                        <th scope="col" class="px-6 py-3">
                                            Email
                                        </th>
                                        <th scope="col" class="px-6 py-3">
                                            Username
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr class="bg-white  dark:bg-gray-800" v-for="item in getListUsers.content"
                                        :key="item.id">
                                        <td scope="row"
                                            class="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
                                            {{ item.email }}
                                        </td>
                                        <td class="px-6 py-4">
                                            {{ item.username }}
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <nav class="flex items-center flex-column flex-wrap md:flex-row justify-between pt-4"
                            aria-label="Table navigation">
                            <span
                                class="text-sm font-normal text-gray-500 dark:text-gray-400 mb-4 md:mb-0 block w-full md:inline md:w-auto">Showing
                                <span class="font-semibold text-gray-900 dark:text-white">
                                    {{ (pageInfo.pageNumber - 1) * pageInfo.pageSize + 1 }} -
                                    {{ Math.min(pageInfo.pageNumber * pageInfo.pageSize, pageInfo.totalElements) }}
                                </span> of <span class="font-semibold text-gray-900 dark:text-white">{{
                                    pageInfo.totalElements
                                }}</span></span>
                            <ul class="inline-flex -space-x-px rtl:space-x-reverse text-sm h-8">
                                <li v-if="this.pageInfo.pageNumber > 1"> <a href="#" @click="goToPreviousPage"
                                        class="flex items-center justify-center px-3 h-8 ms-0 leading-tight text-gray-500 bg-white border border-gray-300 rounded-s-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">
                                        Previous</a>
                                </li>
                                <li v-for="pageNumber in visiblePages" :key="pageNumber">
                                    <a href="#" aria-current="page" @click="goToPage(pageNumber)"
                                        :class="{ 'bg-blue-700 text-white': pageNumber === pageInfo.pageNumber }"
                                        class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{{
                                            pageNumber }}</a>
                                </li>
                                <li>
                                    <a href="#" @click="goToNextPage"
                                        class="flex items-center justify-center px-3 h-8 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-lg hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">Next</a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            breadCrumb: {
                root: { text: "Home", url: "" },
                child: [
                    { text: "Dashboard", url: "" },
                    { text: "Users", url: "" },
                    { text: "List", url: "" }
                ]
            },
            pageInfo: {
                totalPages: 0,
                pageNumber: 0,
                visiblePages: 5,
                pageSize: 10,
                totalElements: 0,
            },
        }
    },
    created() {
        var parameters = this.$route.params
        this.fetchData(parameters.idAgensi, parameters.agensi, parseInt(parameters.pageNumber))
    },
    computed: {
        getListUsers() {
            return this.$store.getters.getListUsers
        },
        visiblePages() {
            const activePage = this.pageInfo.pageNumber;
            const totalVisiblePages = this.pageInfo.visiblePages;
            const totalPages = this.pageInfo.totalPages;

            let startPage = activePage - Math.floor(totalVisiblePages / 2)
            startPage = Math.max(startPage, 1)
            let endPage = startPage + totalVisiblePages - 1
            endPage = Math.min(endPage, totalPages)

            if (endPage === totalPages && totalPages - totalVisiblePages + 1 > 0) {
                startPage = totalPages - totalVisiblePages + 1
            }

            let visiblePages = Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i)

            if (endPage < totalPages) {
                visiblePages.push(endPage + 1)
            }
            return visiblePages;
        }
    },
    methods: {
        async fetchData(idAgensi, agensi, pageNumber) {
            this.pageInfo.pageNumber = pageNumber
            this.agensi = agensi
            this.idAgensi = idAgensi

            await this.$store.dispatch('listUsers', {
                index: (this.pageInfo.pageNumber - 1),
                limit: this.pageInfo.pageSize,
            }).then(() => {
                this.isLoadingScreen = true
                this.pageInfo.totalElements = this.getListUsers.totalElements
                this.pageInfo.totalPages = this.getListUsers.totalPages
            }).finally(() => {
                this.isLoadingScreen = false
            })

        },
        async goToPage(pageNumber) {
            if (pageNumber >= 1 && pageNumber <= this.pageInfo.totalPages) {
                await this.fetchData(this.idAgensi, this.agensi, pageNumber)
                this.tempRouteList(this.idAgensi, this.agensi, pageNumber)
            }
        },
        async goToNextPage() {
            const nextPageNumber = this.pageInfo.pageNumber + 1;
            if (nextPageNumber <= this.pageInfo.totalPages) {
                await this.fetchData(this.idAgensi, this.agensi, nextPageNumber)
                this.tempRouteList(this.idAgensi, this.agensi, nextPageNumber)
            }
        },
        async goToPreviousPage() {
            const previousPageNumber = this.pageInfo.pageNumber - 1
            if (previousPageNumber >= 1) {
                await this.fetchData(this.idAgensi, this.agensi, previousPageNumber)
                this.tempRouteList(this.idAgensi, this.agensi, previousPageNumber)
            }
        },
        tempRouteList(idAgensi, agensi, pageNumber) {
            this.$router.push({ name: 'list-users', params: { idAgensi: idAgensi, agensi: agensi, pageNumber: pageNumber } })
        },
        async exportData(format) {
            await this.$store.dispatch('exportUsers', {
                format: format
            }).then(() => {
                this.isLoadingScreen = true
            }).finally(() => {
                this.isLoadingScreen = false
            })

        },
    }
}
</script>